import React, {useState, useEffect, Fragment} from 'react';
import { Route, Redirect } from "react-router-dom";
import SignUpForm from './SignUpForm'

const SignUp = () =>{

    const [type, setType] = useState('signup')

    return (
    <Fragment>
    <img className="logo" src="https://selleraider.com/wp-content/uploads/2020/10/cropped-LargeSnap-1.png"/>
    {/* <img src="https://selleraider.com/wp-content/uploads/2021/02/cropped-image.png"/> */}
    <h1><i> SellerAider</i></h1>
    {/* <a href="https://selleraider.com/depop-bot/faq">If you are having issues logging in read the F.A.Q</a> */}
    <p>Log In</p>
    <SignUpForm />
    </Fragment>
    )
}

export default  SignUp;