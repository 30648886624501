import './App.css';
import SignUp from './components/SignUp/SignUp'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import React, {useState, useEffect, Fragment} from 'react';
import View from './components/shared/View';

function App() {
  return(
    <View>
        <Router>
            <Switch>
                <Route path="/login" render={(props)=><SignUp {...props}/>}/>
                <Route path="/" render={(props)=><SignUp {...props}/>}/>
            </Switch>
        </Router>
    </View>
)
}

export default App;
