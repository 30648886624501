import React, {useState, useEffect} from 'react';

const View = ({children}) =>{
    return (
    <div className="container">
        {children}
    </div>
    )
}

export default  View;