import React, {useState, useEffect, Fragment} from 'react';

const SignupForm = () =>{
    const [data, setData] = useState({
        email: '',
        username: '',
        password: '',
        password2: '' })

   return  (
     <div className="signup-form">
        <input className="form__input" placeholder="Email" type="email" onChange={(e)=>{
            setData({...data, email: e.target.value.trim().toLowerCase()})
        }}/>
        <button className="btn" onClick={()=>{
            window.location.href = 'https://selleraider.com/depop-bot/faq'
        }}>DONE</button>
    </div>
    )

}

export default  SignupForm;